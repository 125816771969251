var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-2 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "upload" },
                }),
                _vm.selectedDate
                  ? [_vm._v(" " + _vm._s(_vm.formattedSelectedDay) + " ")]
                  : [_vm._v(" Attendance Uploader ")],
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.schoolDay
                  ? [
                      _c("input", {
                        staticStyle: { display: "none" },
                        attrs: {
                          id: "file-input",
                          type: "file",
                          name: "file",
                          accept: ".xls",
                        },
                        on: { change: _vm.onFileBrowseSelect },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.triggerFileBrowser.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Upload Files ")]
                      ),
                    ]
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          on: {
            dragleave: _vm.onDragLeave,
            dragover: _vm.onDragOver,
            drop: _vm.onDrop,
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                "no-center": "",
                opacity: "1",
                blur: "10px",
                show: _vm.encryptionEnabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center pt-4 kt-font-lg" },
                        [
                          _vm._v(
                            " PII is currently encrypted. Please unlock PII to send a message. "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center pt-4" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-brand btn-bold btn-upper mt-4",
                                staticStyle: { "min-width": "160px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrypt()
                                  },
                                },
                              },
                              [_vm._v(" Unlock PII ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.showDropZone
                ? _c("div", { staticClass: "w-100" }, [
                    _c(
                      "a",
                      {
                        staticClass: "kt-header__brand-logo w-100 text-center",
                      },
                      [
                        _c("SVGIcon", {
                          staticClass: "logo loading",
                          attrs: { name: "dropZone" },
                        }),
                        _c("h1", { staticClass: "kt-font-bolder" }, [
                          _vm._v(" Drop files here to upload! "),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__body pt-5" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 pb-5" }, [
                          _vm.isNYC
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-outline-info alert-elevate",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c("div", { staticClass: "alert-text" }, [
                                    _c("h4", { staticClass: "alert-heading" }, [
                                      _vm._v(" Attendance Uploader "),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " Drag and drop RPAD (period attendance) or RDAL (daily attendance) files here to upload. "
                                      ),
                                    ]),
                                    _c("hr"),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        " Mark non-school days on the calendar to exclude them from attendance. "
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-outline-info alert-elevate",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c("div", { staticClass: "alert-text" }, [
                                    _c("h4", { staticClass: "alert-heading" }, [
                                      _vm._v(" Attendance Uploader "),
                                    ]),
                                    _c("p", [
                                      _vm._v(" Drag and drop "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "kt-link kt-font-bolder",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.downloadPeriodSample.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("period attendance")]
                                      ),
                                      _vm._v(" or "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "kt-link kt-font-bolder",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.downloadDailySample.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("daily attendance")]
                                      ),
                                      _vm._v(" files here to upload. "),
                                    ]),
                                    _c("hr"),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        " Mark non-school days on the calendar to exclude them from attendance. "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                        ]),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.selectedDate || _vm.logs.length
                                ? "col-lg-4"
                                : "d-none",
                            ],
                          },
                          [
                            _vm.selectedDate && _vm.validDate
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "form-control kt-input",
                                        attrs: {
                                          dropbottom: "",
                                          min: _vm.calendarOptions.validRange
                                            .start,
                                          max: _vm.calendarOptions.validRange
                                            .end,
                                        },
                                        on: { input: _vm.focusCalendar },
                                        model: {
                                          value: _vm.selectedDate,
                                          callback: function ($$v) {
                                            _vm.selectedDate = $$v
                                          },
                                          expression: "selectedDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-center py-4" },
                                    [
                                      _vm.isSchoolDay
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-wide btn-upper btn-light",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleSchoolDay.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Mark as Non-School Day ")]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-wide btn-upper btn-primary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleSchoolDay.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Mark as School Day ")]
                                          ),
                                    ]
                                  ),
                                  !_vm.schoolDay
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-light alert-elevate",
                                          attrs: { role: "alert" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-icon" },
                                            [
                                              _c("i", {
                                                staticClass: "flaticon-warning",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " Non-School Day, no upload needed "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : [
                                        _vm.dailyAttendanceUploaded
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-success alert-elevate",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "alert-icon" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "flaticon2-check-mark",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "alert-text" },
                                                  [
                                                    _vm._v(
                                                      " Daily Attendance Submitted "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.periodAttendanceUploaded
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-primary alert-elevate",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "alert-icon" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "flaticon2-check-mark",
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "alert-text" },
                                                  [
                                                    _vm._v(
                                                      " Period Attendance Submitted "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                ]
                              : _vm._e(),
                            _vm.logs.length
                              ? _c(
                                  "div",
                                  { staticClass: "logs text-left" },
                                  _vm._l(_vm.logs, function (item, idx) {
                                    return _c("p", { key: `item_${idx}` }, [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            item.status == "danger"
                                              ? "text-danger"
                                              : "",
                                            item.status == "success"
                                              ? "text-success"
                                              : "",
                                            item.status == "info"
                                              ? "text-info"
                                              : "",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.message) + " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.selectedDate || _vm.logs.length
                                ? "col-lg-8"
                                : "col-12",
                            ],
                          },
                          [
                            _vm.ready
                              ? _c("FullCalendar", {
                                  ref: "calendar",
                                  attrs: { options: _vm.calendarOptions },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }