<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="upload"
                class="kt-svg-icon mt-2 mr-3"
            />
            <template v-if="selectedDate">
                {{ formattedSelectedDay }}
            </template>
            <template v-else>
                Attendance Uploader
            </template>
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <template v-if="schoolDay">
                <input
                    id="file-input"
                    type="file"
                    name="file"
                    accept=".xls"
                    style="display: none"
                    @change="onFileBrowseSelect"
                >
                <button
                    class="btn btn-primary"
                    style="cursor: pointer"
                    @click.stop.prevent="triggerFileBrowser"
                >
                    Upload Files
                </button>
            </template>
        </template>
    </GeneralSubHeader>
    <div
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        @drop="onDrop"
    >
        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to send a message.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div
                v-if="showDropZone"
                class="w-100"
            >
                <a class="kt-header__brand-logo w-100 text-center">
                    <SVGIcon
                        name="dropZone"
                        class="logo loading"
                    />

                    <h1 class="kt-font-bolder">
                        Drop files here to upload!
                    </h1>
                </a>
            </div>

            <div v-else class="kt-portlet">
                <div class="kt-portlet__body pt-5">
                    <div class="row">
                        <div class="col-12 pb-5">
                            <div
                                v-if="isNYC"
                                class="alert alert-outline-info alert-elevate"
                                role="alert"
                            >
                                <div class="alert-text">
                                    <h4 class="alert-heading">
                                        Attendance Uploader
                                    </h4>
                                    <p>
                                        Drag and drop RPAD (period attendance) or RDAL (daily attendance) files here to upload.
                                    </p>
                                    <hr>
                                    <p class="mb-0">
                                        Mark non-school days on the calendar to exclude them from attendance.
                                    </p>
                                </div>
                            </div>
                            <div
                                v-else
                                class="alert alert-outline-info alert-elevate"
                                role="alert"
                            >
                                <div class="alert-text">
                                    <h4 class="alert-heading">
                                        Attendance Uploader
                                    </h4>
                                    <p>
                                        Drag and drop
                                        <a
                                            href="#"
                                            class="kt-link kt-font-bolder"
                                            @click.stop.prevent="downloadPeriodSample"
                                        >period attendance</a>
                                        or <a
                                            href="#"
                                            class="kt-link kt-font-bolder"
                                            @click.stop.prevent="downloadDailySample"
                                        >daily attendance</a>
                                        files here to upload.
                                    </p>
                                    <hr>
                                    <p class="mb-0">
                                        Mark non-school days on the calendar to exclude them from attendance.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div :class="[(selectedDate || logs.length) ? 'col-lg-4' : 'd-none']">
                            <template v-if="selectedDate && validDate">
                                <div class="form-group">
                                    <b-form-datepicker
                                        v-model="selectedDate"
                                        dropbottom
                                        :min="calendarOptions.validRange.start"
                                        :max="calendarOptions.validRange.end"
                                        class="form-control kt-input"
                                        @input="focusCalendar"
                                    />
                                </div>

                                <div class="text-center py-4">
                                    <button
                                        v-if="isSchoolDay"
                                        type="button"
                                        class="btn btn-wide btn-upper btn-light"
                                        @click.stop.prevent="toggleSchoolDay"
                                    >
                                        Mark as Non-School Day
                                    </button>
                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-wide btn-upper btn-primary"
                                        @click.stop.prevent="toggleSchoolDay"
                                    >
                                        Mark as School Day
                                    </button>
                                </div>

                                <div
                                    v-if="!schoolDay"
                                    class="alert alert-light alert-elevate"
                                    role="alert"
                                >
                                    <div class="alert-icon">
                                        <i class="flaticon-warning" />
                                    </div>
                                    <div class="alert-text">
                                        Non-School Day, no upload needed
                                    </div>
                                </div>

                                <template v-else>
                                    <div
                                        v-if="dailyAttendanceUploaded"
                                        class="alert alert-success alert-elevate"
                                        role="alert"
                                    >
                                        <div class="alert-icon">
                                            <i class="flaticon2-check-mark" />
                                        </div>
                                        <div class="alert-text">
                                            Daily Attendance Submitted
                                        </div>
                                    </div>
                                    <div
                                        v-if="periodAttendanceUploaded"
                                        class="alert alert-primary alert-elevate"
                                        role="alert"
                                    >
                                        <div class="alert-icon">
                                            <i class="flaticon2-check-mark" />
                                        </div>
                                        <div class="alert-text">
                                            Period Attendance Submitted
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <div v-if="logs.length" class="logs text-left">
                                <p
                                    v-for="(item, idx) in logs"
                                    :key="`item_${idx}`"
                                >
                                    <span
                                        :class="[
                                            item.status == 'danger' ? 'text-danger' : '',
                                            item.status == 'success' ? 'text-success' : '',
                                            item.status == 'info' ? 'text-info' : '',
                                        ]"
                                    >
                                        {{ item.message }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div :class="[(selectedDate || logs.length) ? 'col-lg-8' : 'col-12']">
                            <FullCalendar
                                v-if="ready"
                                ref="calendar"
                                :options="calendarOptions"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</div>
</template>

<script lang="ts">
import moment from 'moment';
import * as XLSX from 'xlsx';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';

import listPlugin from '@fullcalendar/list';

import async from 'async';
import Types from '../store/Types';
import studentMixins from '../store/mixins/studentMixins';
import * as network from '../network';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';

export default {
    name: 'UploadAttendance',
    components: {
        FullCalendar,
        GeneralSubHeader,
    },
    mixins: [studentMixins],
    data() {
        return {
            logs: [],
            ready: false,
            saving: false,
            loading: false,
            selectedDate: null,
            showDropZone: false,
            key: 1,
            files: [],
            dragTimer: null,
            schoolDayCalendar: {},
            calendarOptions: {
                selectable: true,
                plugins: [
                    dayGridPlugin,
                    bootstrapPlugin,
                    listPlugin,
                    interactionPlugin,
                ],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                },
                validRange: {
                    start: null,
                    end: null,
                },
                themeSystem: 'bootstrap',
                weekends: false,
                dateClick: this.handleDateClick,
                events: [],
            },
        };
    },
    computed: {
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        user() {
            return this.$store.state.user;
        },
        isNYC() {
            return this.user.school.externalDistrictId == 'NYC';
        },
        isSchoolDay() {
            const { selectedDate } = this;
            if (!selectedDate) return false;
            const day = this.schoolDayCalendar[selectedDate];
            if (!day) return false;
            return !day.nonSchoolDay;
        },
        validDate() {
            return this.selectedDate && this.schoolDayCalendar.hasOwnProperty(this.selectedDate);
        },
        formattedSelectedDay() {
            if (!this.selectedDate) return '';
            return moment(this.selectedDate).format('dddd, MMM Do YYYY');
        },
        schoolDay() {
            const { schoolDayCalendar, selectedDate } = this;
            if (!selectedDate) return false;
            if (!schoolDayCalendar.hasOwnProperty(selectedDate)) return false;
            return !schoolDayCalendar[selectedDate].nonSchoolDay;
        },
        dailyAttendanceUploaded() {
            const { schoolDayCalendar, selectedDate } = this;
            if (!selectedDate) return false;
            if (!schoolDayCalendar.hasOwnProperty(selectedDate)) return false;
            return schoolDayCalendar[selectedDate].dailyAttendanceUploaded;
        },
        periodAttendanceUploaded() {
            const { schoolDayCalendar, selectedDate } = this;
            if (!selectedDate) return false;
            if (!schoolDayCalendar.hasOwnProperty(selectedDate)) return false;
            return schoolDayCalendar[selectedDate].periodAttendanceUploaded;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        populate() {
            // const v = this;
            const { loadCalendar, showError } = this;
            const { schoolTermId, schoolId } = this.user.school;
            const { termStart, termEnd } = this.user.school;
            this.calendarOptions.validRange.start = termStart;
            this.calendarOptions.validRange.end = termEnd;
            this.selectedDate = moment().format('YYYY-MM-DD');
            if (moment().day() == 0 || moment().day() == 6) {
                this.selectedDate = moment().day(-2).format('YYYY-MM-DD');
            }

            network.schoolDayCalendar.listSchoolDayCalendars({ url: { schoolTermId, schoolId } }, (err, resp) => {
                if (err) return showError('Error Refreshing');
                const { schoolDayCalendar } = resp;
                loadCalendar(schoolDayCalendar);
                this.ready = true;
            });
        },
        log(message, status = 'info') {
            this.logs.push({ message, status });
        },
        focusCalendar() {
            this.$refs.calendar.getApi().gotoDate(this.selectedDate);
        },
        loadCalendar(schoolDayCalendar) {
            this.schoolDayCalendar = { ...schoolDayCalendar };
            this.calendarOptions.events = createEventsForCalendar(schoolDayCalendar);
        },
        toggleSchoolDay() {
            if (this.saving) return;
            this.saving = true;

            const { selectedDate, schoolDayCalendar } = this;
            const { loadCalendar, showError } = this;
            if (!selectedDate) return;
            const schoolDay = schoolDayCalendar[selectedDate];
            if (!schoolDay) return;
            schoolDay.nonSchoolDay = !schoolDay.nonSchoolDay;

            const { schoolTermId, schoolId } = this.user.school;
            const params = { url: { schoolTermId, schoolId }, body: { schoolDayCalendar: schoolDay } };
            network.schoolDayCalendar.upsertSchoolDayCalendar(params, (err) => {
                this.saving = false;
                if (err) return showError('Error updating school day');
                schoolDayCalendar[selectedDate] = schoolDay;
                loadCalendar(schoolDayCalendar);
            });
        },
        handleDateClick(arg) {
            this.selectedDate = arg.dateStr;
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        onFileBrowseSelect(e) {
            const files = Array.from(e.target.files);
            this.loadFiles(files);
        },
        loadFiles(files) {
            this.hideDropZone();
            if (this.loading) return;
            this.loading = true;
            const { processFile, showError } = this;
            async.eachSeries(files, (file, nextFile) => {
                if (file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.csv')) {
                    processFile(file, nextFile);
                } else {
                    return nextFile(`Invalid file type for ${file.name}`);
                }
            }, (err) => {
                this.loading = false;
                if (err) return showError(err);
                // window.location.reload();
            });
        },
        processFile(file, callback) {
            if (this.saving) return;
            this.saving = true;

            const reader = new FileReader();
            const { uploadRDAL, uploadDailyAttendanceCSV, log } = this;
            const { uploadRPAD, uploadPeriodAttendanceCSV } = this;
            reader.onload = (f) => {
                // @ts-ignore
                const data = new Uint8Array(f.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, blankrows: false });

                // NYC RPAD
                if (sheetName === 'RPAD') {
                    const date = jsonData[2][0].split('End Date');
                    if (date.length < 2) return callback('Invalid file format');

                    const txtStartDate = date[0].replace(/(?![/])\D/g, '');
                    const txtEndDate = date[1].replace(/(?![/])\D/g, '');
                    const startDate = moment(txtStartDate, 'MM/DD/YY').format('YYYY-MM-DD');
                    const endDate = moment(txtEndDate, 'MM/DD/YY').format('YYYY-MM-DD');

                    log(`Found records for ${startDate} - ${endDate}`, 'info');
                    log('Daily Attendance File RPAD identified', 'info');
                    return uploadRPAD(startDate, endDate, jsonData, callback);
                }

                const line = jsonData[0][0];

                // NYC RDAL
                if (sheetName == 'Sheet1' && line.includes('DAILY ATTENDANCE LIST - SCHOOL NUMBER')) { // csv file
                    const dateString = jsonData[3][5];
                    const schoolDate = moment(dateString, 'MM/DD/YY').format('YYYY-MM-DD');
                    log(`Found records for ${schoolDate}`, 'info');
                    log('Daily Attendance File RDAL identified', 'info');
                    return uploadRDAL(schoolDate, jsonData, callback);
                }

                // non nyc csv file
                if (sheetName == 'Sheet1' && line.includes('Daily Attendance for')) {
                    const dateString = line.replace(/(?![/])\D/g, '');
                    const schoolDate = moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    log(`Found records for ${schoolDate}`, 'info');
                    log('Daily Attendance CSV identified', 'info');
                    return uploadDailyAttendanceCSV(schoolDate, jsonData, callback);
                }

                // non nyc csv file
                if (sheetName == 'Sheet1' && line.includes('Period Attendance')) {
                    const dateString = line.replace(/(?![/])\D/g, '');
                    const schoolDate = moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
                    log(`Found records for ${schoolDate}`, 'info');
                    log('Period Attendance CSV identified', 'info');
                    return uploadPeriodAttendanceCSV(schoolDate, jsonData, callback);
                }

                return callback('Invalid file format');
            };
            reader.readAsArrayBuffer(file);
        },
        uploadRDAL(attendanceDate, jsonData, callback) {
            const v = this;
            const { loadCalendar, log, processDailyAttendanceUpload } = this;
            const { students } = this.$stire.state.database;
            const schoolDayCalendar = { ...this.schoolDayCalendar };

            const schoolDay = { ...schoolDayCalendar[attendanceDate] };
            if (!schoolDay) return callback('Invalid school day found');

            const attendanceRecords = jsonData
                .map((row, index) => {
                    if (index <= 4) return null;
                    let [attendanceState, studentId] = row;
                    if (studentId.length !== 9) return null;
                    if (['P', 'A', 'L', 'E'].indexOf(attendanceState) == -1) return null;
                    if (!studentId) return null;

                    if (attendanceState == 'P') attendanceState = 'Present';
                    if (attendanceState == 'A') attendanceState = 'Absent';
                    if (attendanceState == 'L') attendanceState = 'Late';
                    if (attendanceState == 'E') attendanceState = 'Excused';
                    const student = students.find((s) => s.extStudentId == studentId);
                    if (!student) {
                        log(`Student with ID ${studentId} not found`, 'danger');
                        return null;
                    }
                    studentId = student.studentId;
                    return {
                        studentId,
                        attendanceState,
                        attendanceComment: null,
                    };
                });

            const unique = new Map();
            attendanceRecords.forEach((row) => {
                if (!row) return;
                const key = `${row.studentId}`;
                unique.set(key, { ...row });
            });
            const records = [...unique.values()];

            if (records.length == 0) return callback('No valid records found');
            processDailyAttendanceUpload(attendanceDate, records, (err) => {
                v.saving = false;
                if (err) return callback(err);
                schoolDay.dailyAttendanceUploaded = true;
                schoolDay.nonSchoolDay = false;
                schoolDayCalendar[attendanceDate] = schoolDay;

                log(`Daily Attendance for ${attendanceDate} submitted with ${records.length} records`, 'info');

                loadCalendar(schoolDayCalendar);
                callback();
            });
        },
        uploadRPAD(startDate, endDate, jsonData, callback) {
            const v = this;

            const { schoolDayCalendar, loadCalendar, log } = this;
            const { processCourseAttendanceUpload } = this;
            const { schoolTermId, schoolId } = this.user.school;
            const { students } = this.$store.state.database;
            const { courseSections } = this.$store.state.database;

            const attendanceDates = [];
            let currentDate = moment(startDate);
            let hasBadDate = false;
            while (currentDate.isSameOrBefore(endDate)) {
                if (!schoolDayCalendar[currentDate.format('YYYY-MM-DD')]) hasBadDate = true;
                attendanceDates.push(currentDate.format('YYYY-MM-DD'));
                currentDate = currentDate.add(1, 'days');
            }
            if (hasBadDate) return callback('Invalid school day found');

            async.auto({
                clear(next) {
                    network.attendance.clearPeriodAttendance({ url: { schoolTermId, schoolId }, body: { attendanceDates } }, next);
                },
                upload: ['clear', (results, next) => {

                    const attendanceRecords = jsonData
                        .map((row, index) => {
                            if (index < 4) return null;

                            let [attendanceDate, , , studentEnrollmentId, courseSectionId, attendanceState, attendanceComment] = row;
                            const dateParsed = moment(attendanceDate, 'MM/DD/YY', true);
                            if (!dateParsed.isValid()) {
                                log(`Invalid date found in row ${index + 1}`, 'danger');
                                return null;
                            }
                            if (!courseSectionId || !studentEnrollmentId) return null;
                            if (studentEnrollmentId.length < 5 && studentEnrollmentId.length > 13) return null;
                            if (['P', 'A', 'L', 'E'].indexOf(attendanceState) == -1) {
                                log(`Invalid attendance state of '${attendanceState}' found in row ${index + 1}, expects P, A, L, E`, 'danger');
                                return null;
                            }
                            attendanceDate = moment(attendanceDate, 'MM/DD/YY').format('YYYY-MM-DD');
                            if (attendanceState == 'P') attendanceState = 'Present';
                            if (attendanceState == 'A') attendanceState = 'Absent';
                            if (attendanceState == 'L') attendanceState = 'Late';
                            if (attendanceState == 'E') attendanceState = 'Excused';

                            const student = students.find((s) => s.extStudentId == studentEnrollmentId);
                            if (!student) {
                                log(`Student with ID ${studentEnrollmentId} not found`, 'danger');
                                return null;
                            }

                            studentEnrollmentId = student.studentEnrollmentId;
                            const courseSection = courseSections.find((cs) => {
                                const match = cs.extCourseSectionId == courseSectionId;
                                if (match) return true;
                                // could be ABCD-01 or ACBD-1
                                return cs.extCourseSectionId == courseSectionId.replace(/-0/g, '-');
                            }) || null;

                            if (!courseSection) {
                                log(`Course Section with ID ${courseSectionId} not found`, 'danger');
                                return null;
                            }
                            courseSectionId = courseSection.courseSectionId;
                            attendanceComment = attendanceComment || null;

                            return {
                                studentEnrollmentId,
                                courseSectionId,
                                attendanceDate,
                                attendanceState,
                                attendanceComment,
                            };
                        });

                    const unique = new Map();
                    attendanceRecords.forEach((row) => {
                        if (!row) return;
                        const key = `${row.studentEnrollmentId}_${row.courseSectionId}_${row.attendanceDate}`;
                        unique.set(key, { ...row });
                    });
                    const records = [...unique.values()];
                    if (records.length == 0) return callback('No valid records found');

                    processCourseAttendanceUpload(records, (err2) => {
                        if (err2) return next(err2);
                        log(`Uploaded ${records.length} records`, 'info');
                        next();
                    });
                }],
                calendar: ['upload', (results, next) => {
                    async.eachOfSeries(attendanceDates, (date, idx, nextDate) => {
                        const schoolDay = { ...schoolDayCalendar[date] };
                        schoolDay.periodAttendanceUploaded = true;
                        schoolDay.nonSchoolDay = false;
                        schoolDay.schoolTermId = schoolTermId;
                        schoolDayCalendar[date] = schoolDay;
                        log(`Period Attendance for ${date} submitted`, 'info');
                        network.schoolDayCalendar.upsertSchoolDayCalendar({ url: { schoolTermId, schoolId }, body: { schoolDayCalendar: schoolDay } }, nextDate);
                    }, next);
                }],
            }, 10, (err) => {
                v.saving = false;
                if (err) return callback(err);
                loadCalendar(schoolDayCalendar);
                callback();
            });
        },
        uploadDailyAttendanceCSV(attendanceDate, jsonData, callback) {
            const v = this;
            const { loadCalendar, log, processDailyAttendanceUpload } = this;
            const { students } = this.$store.state.database;
            const schoolDayCalendar = { ...this.schoolDayCalendar };

            const schoolDay = { ...schoolDayCalendar[attendanceDate] };
            if (!schoolDay) return callback('Invalid school day found');

            const attendanceRecords = jsonData
                .map((row, index) => {
                    if (index == 0) return null;
                    let [studentId, attendanceState, attendanceComment] = row;
                    if (studentId.length < 5 && studentId.length > 13) return null;
                    if (['P', 'A', 'L', 'E'].indexOf(attendanceState) == -1) return null;
                    if (!studentId) return null;
                    if (attendanceState == 'P') attendanceState = 'Present';
                    if (attendanceState == 'A') attendanceState = 'Absent';
                    if (attendanceState == 'L') attendanceState = 'Late';
                    if (attendanceState == 'E') attendanceState = 'Excused';
                    const student = students.find((s) => s.extStudentId == studentId);
                    if (!student) {
                        log(`Student with ID ${studentId} not found`, 'danger');
                        return null;
                    }
                    studentId = student.studentId;
                    attendanceComment = attendanceComment || null;
                    return {
                        studentId,
                        attendanceState,
                        attendanceComment,
                    };
                });

            const unique = new Map();
            attendanceRecords.forEach((row) => {
                if (!row) return;
                const key = `${row.studentId}`;
                unique.set(key, { ...row });
            });
            const records = [...unique.values()];
            if (records.length == 0) return callback('No valid records found');

            processDailyAttendanceUpload(attendanceDate, records, (err) => {
                v.saving = false;
                if (err) return callback(err);
                schoolDay.dailyAttendanceUploaded = true;
                schoolDay.nonSchoolDay = false;
                schoolDayCalendar[attendanceDate] = schoolDay;

                log(`Daily Attendance for ${attendanceDate} submitted with ${attendanceRecords.length} records`, 'info');

                loadCalendar(schoolDayCalendar);
                callback();
            });
        },
        uploadPeriodAttendanceCSV(attendanceDate, jsonData, callback) {
            const v = this;
            const { schoolTermId, schoolId } = this.user.school;
            const { students, courseSections } = this.$store.state.database;
            const { loadCalendar, log, processCourseAttendanceUpload } = this;
            const schoolDayCalendar = { ...this.schoolDayCalendar };

            const schoolDay = { ...schoolDayCalendar[attendanceDate] };
            if (!schoolDay) return callback('Invalid school day found');

            async.auto({
                clear(next) {
                    network.attendance.clearPeriodAttendance({ url: { schoolTermId, schoolId }, body: { attendanceDates: [attendanceDate] } }, next);
                },
                upload: ['clear', (results, next) => {
                    const attendanceRecords = jsonData
                        .map((row, index) => {
                            if (index <= 1) return null;

                            let [studentEnrollmentId, courseSectionId, attendanceState, attendanceComment] = row;

                            if (!courseSectionId || !studentEnrollmentId) return null;
                            if (studentEnrollmentId.length < 5 && studentEnrollmentId.length > 13) return null;
                            if (['P', 'A', 'L', 'E'].indexOf(attendanceState) == -1) {
                                log(`Invalid attendance state of '${attendanceState}' found in row ${index + 1}, expects P, A, L, E`, 'danger');
                                return null;
                            }
                            if (attendanceState == 'P') attendanceState = 'Present';
                            if (attendanceState == 'A') attendanceState = 'Absent';
                            if (attendanceState == 'L') attendanceState = 'Late';
                            if (attendanceState == 'E') attendanceState = 'Excused';
                            const student = students.find((s) => s.extStudentId == studentEnrollmentId);
                            if (!student) {
                                log(`Student with ID ${studentEnrollmentId} not found`, 'danger');
                                return null;
                            }

                            studentEnrollmentId = student.studentEnrollmentId;
                            const courseSection = courseSections.find((cs) => cs.extCourseSectionId == courseSectionId) || null;
                            if (!courseSection) {
                                log(`Course Section with ID ${courseSectionId} not found`, 'danger');
                                return null;
                            }
                            courseSectionId = courseSection.courseSectionId;
                            attendanceComment = attendanceComment || null;

                            return {
                                studentEnrollmentId,
                                courseSectionId,
                                attendanceDate,
                                attendanceState,
                                attendanceComment,
                            };
                        });

                    const unique = new Map();
                    attendanceRecords.forEach((row) => {
                        if (!row) return;
                        const key = `${row.studentEnrollmentId}_${row.courseSectionId}_${row.attendanceDate}`;
                        unique.set(key, { ...row });
                    });
                    const records = [...unique.values()];
                    if (records.length == 0) return callback('No valid records found');

                    processCourseAttendanceUpload(records, (err2) => {
                        if (err2) return next(err2);
                        log(`Uploaded ${records.length} records`, 'info');
                        next();
                    });
                }],
                calendar: ['upload', (results, next) => {
                    const calendarDate = { ...schoolDayCalendar[attendanceDate] };
                    calendarDate.periodAttendanceUploaded = true;
                    calendarDate.nonSchoolDay = false;
                    calendarDate.schoolTermId = schoolTermId;
                    schoolDayCalendar[attendanceDate] = calendarDate;
                    log(`Period Attendance for ${attendanceDate} submitted`, 'info');
                    network.schoolDayCalendar.upsertSchoolDayCalendar({ url: { schoolTermId, schoolId }, body: { schoolDayCalendar: calendarDate } }, next);
                }],
            }, 10, (err) => {
                v.saving = false;
                if (err) return callback(err);
                loadCalendar(schoolDayCalendar);
                callback();
            });
        },
        processDailyAttendanceUpload(attendanceDate, attendanceRecords, callback) {
            const chunks = createChunks(attendanceRecords, 100);
            const { schoolTermId, schoolId } = this.user.school;
            async.eachOfSeries(chunks, (chunk, idx, nextChunk) => {
                const params = {
                    url: { schoolTermId, schoolId, attendanceDate },
                    body: {
                        attendanceRecords: chunk,
                        deleteAttendance: idx == 0,
                    },
                };
                network.attendance.uploadAttendance(params, nextChunk);
            }, callback);
        },
        processCourseAttendanceUpload(attendanceRecords, callback) {
            const chunks = createChunks(attendanceRecords, 100);
            const { schoolTermId, schoolId } = this.user.school;
            async.eachOfSeries(chunks, (chunk, idx, nextChunk) => {
                const params = {
                    url: { schoolTermId, schoolId },
                    body: {
                        attendanceRecords: chunk,
                    },
                };
                network.attendance.uploadCourseAttendance(params, nextChunk);
            }, callback);
        },
        downloadDailySample() {
            const data = new Blob([`Daily Attendance for: 2024-05-03
StudentId,Attendance,Comment
999000001,A,"Off"
999000002,A,
999000003,A,
999000004,A,
999000005,P,
999000006,L,
999000007,E,
999000008,A,
999000009,A,`], { type: 'application/csv' });

            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = 'daily-attendance-sample.csv';
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);
        },
        downloadPeriodSample() {
            const data = new Blob([`Period Attendance for: 2024-04-15
StudentId,Course,Attendance,Comment
999000001,FSS22-02,A,"Appointments with the doctor."
999000001,HGS43Q-01,A,
999000001,EAS11P-03,A,
999000001,PPS82-01,P,
999000001,SLS21QA-01,L,
999000001,EES81-01,E,
999000001,MES21-01,A,`], { type: 'text/plain' });
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = 'period-attendance-sample.csv';
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0);
        },
        // drag drop stuff
        onDragOver(e) {
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            this.showDropZone = (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')));
        },
        onDragLeave(e) {
            e.stopPropagation();
            e.preventDefault();
            this.hideDropZone();
        },
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files);

            this.loadFiles(files);
        },
        hideDropZone() {
            const v = this;
            if (v.dragTimer) clearTimeout(v.dragTimer);
            v.dragTimer = setTimeout(() => {
                v.showDropZone = false;
            }, 25);
        },
    },
};

function createEventsForCalendar(schoolDayCalendar) {
    const events = [];
    const days = Object.keys(schoolDayCalendar);
    days.forEach((day) => {
        const item = schoolDayCalendar[day];
        const { schoolDayCalendarDate } = item;
        if (item.nonSchoolDay) {
            events.push({
                title: 'Non-School Day',
                start: schoolDayCalendarDate,
                color: '#6c757d',
            });

            events.push({
                start: schoolDayCalendarDate,
                end: schoolDayCalendarDate,
                display: 'background',
                color: '#6c757d',
            });
        } else {
            if (item.dailyAttendanceUploaded) {
                events.push({
                    title: 'Daily Attendance Submitted',
                    start: schoolDayCalendarDate,
                    color: '#28a745',
                });
            }
            if (item.periodAttendanceUploaded) {
                events.push({
                    title: 'Period Attendance Submitted',
                    start: schoolDayCalendarDate,
                    color: '#0f88ef',
                });
            }

            if (item.dailyAttendanceUploaded && item.periodAttendanceUploaded) {
                events.push({
                    start: schoolDayCalendarDate,
                    end: schoolDayCalendarDate,
                    display: 'background',
                    color: '#28a745',
                });
            } else if (item.dailyAttendanceUploaded) {
                events.push({
                    start: schoolDayCalendarDate,
                    end: schoolDayCalendarDate,
                    display: 'background',
                    color: '#f7f1c1',
                });
            } else if (item.periodAttendanceUploaded) {
                events.push({
                    start: schoolDayCalendarDate,
                    end: schoolDayCalendarDate,
                    display: 'background',
                    color: '#ffc107',
                });
            }
        }
    });
    return events;
}

function createChunks(arr, len) {
    const chunks = []; let i = 0; const
        n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}
</script>

<style scoped>

/* drag drop styles */
a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    color: #0f88ef;
    font-size: 2.8rem;
    opacity: 0.4;
}

/* logs */
div.logs {
    font-family: 'Courier New', monospace;
    width: 100%;
    overflow-y: scroll;
    padding-top: 26px;
    max-height: 50vh;
}

div.logs span.kt-badge {
    display: inline-block;
    text-align: center;
    width: 60px;
}

div.logs p {
    font-weight: 600;
}

</style>
